import React, {useState, useEffect} from 'react'
import styles from '../MathExercise/MathExercise.module.css';

const GameTimer = () => {
    const [ minutes, setMinutes ] = useState(0);
    const [ seconds, setSeconds ] =  useState(0);

    useEffect(()=>{
        const interval = setInterval(() => {
            setSeconds(seconds + 1);
            if (seconds === 60) {
                setSeconds(0);
                setMinutes(minutes +1);
            }
        }, 1000);
        return () => clearInterval(interval);

    }, [seconds, minutes]);

    return (
        <div className={styles.gameTimer}>
            <div className={styles.gameTimerIcon} />
            {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}
        </div>
    )
}

export default GameTimer;