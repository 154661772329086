import React, { useState } from 'react';
import styles from './Welcome.module.css';
import { useMathContext } from '../MathExercise/MathContext';
import Settings from './Settings';
import ReactGA from 'react-ga';

function Welcome() {

    const { onClickStage } = useMathContext();

    const [welcomStage, setWelcomStage] = useState('HOME');

    const onClickStart = () => {
        onClickStage('EXERCISE');
        ReactGA.pageview('EXERCISE');
    }

    const onClickWhatsappkButton = () => {
        ReactGA.pageview('whatsapp');
        window.open('whatsapp://send?text=רציתי לשתף אותך במשחק מעולה לתרגול מתמטיקה: https://math.sigalitam.com', '_blank', 'noopener,noreferrer');
    }

    const onClickSettingsButton = () => {
        if(welcomStage === 'SETTINGS'){
            setWelcomStage('HOME');
        } else {
            setWelcomStage('SETTINGS');
            ReactGA.pageview('SETTINGS');
        }
    }

    const onClickInfokButton = () => {
        if(welcomStage === 'INFO'){
            setWelcomStage('HOME');
        } else {
            setWelcomStage('INFO');
            ReactGA.pageview('INFO');
        }
    }
    
    return (
        <>
            <div className={styles.welcomeWrapper}>
                { welcomStage === 'HOME' &&
                    <>
                        <h1>מתרגלים מתמטיקה בכיף</h1>
                        <p><b>איזה כיף שהגעת לפה!</b> <br/> אנחנו כאן כדי לעזור לך לתרגל מתמטיקה דרך משחק, כך תוכל גם ללמוד וגם להנות.</p>
                        <button className="btn" onClick={onClickStart}>אני רוצה לשחק!</button>  
                    </>
                } 
                { welcomStage === 'INFO' &&
                    <>
                        <h1>קצת על המשחק</h1>
                        <p>המשחק הזה נוצר במיוחד בשבילך, כדי לעזור לך לתרגל מתמטיקה ברמות שונות, בצורה כיפית והנה.</p>
                        <p>למשחק מספר רמות ואפשרויות, בלחיצה על גלגל השיניים ניתן לשנות את ההגדרות המשחק.</p>
                        <p>במסך התרגיל ניתן לעבור למצב ״קשקוש״ שבו תוכל לחשב את התרגיל בצורה פשוטה וקלה יותר.</p>
                        <p>אהבת את המשחק? אנו מזמינים אותך לשתף אותו בוואטסאפ.</p>
                        <button className="btn" onClick={onClickInfokButton}>הבנתי</button> 
                    </>
                }
                { welcomStage === 'SETTINGS' && <Settings onClickSettingsButton={onClickSettingsButton} /> }
                    
            </div>
            <div className={styles.welcomeFooter}>
                <div className={`${welcomStage === 'INFO' ? styles.home : styles.info}`} onClick={onClickInfokButton} />
                <div className={`${welcomStage === 'SETTINGS' ? styles.home : styles.settings}`} onClick={onClickSettingsButton} />
                <div className={styles.whatsapp} onClick={onClickWhatsappkButton} />
            </div>
        </>
    );
}

export default Welcome;