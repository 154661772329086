import React, {useContext, createContext, useState, useEffect} from "react";

const OPERATORS = [
    {name:'חיבור', symbol: '+', value: 'plus', checked: true},
    {name:'חיסור', symbol: '-', value: 'minus', checked: false},
    {name:'כפל', symbol: 'X', value: 'multiple', checked: false},
];

export const MathContext = createContext();
export const useMathContext = () => useContext(MathContext);

const MathContextProvider = ({ children, values }) => {
    const [settings, setSettings] = useState(() => {
        const saved = localStorage.getItem('settings');
        const initialValue = JSON.parse(saved);
        return initialValue || {maxNumber: 100, steps: 10, operators: OPERATORS};
    });
    
    const [activeStep, setActiveStep] = useState(0);
    const [exercises, setExercises] = useState([]);

    useEffect(() => {
        localStorage.setItem("settings", JSON.stringify(settings));
    }, [settings]);

    const onClickStep = (exercise) => {
        if (settings.steps - 1 === activeStep){
          values.onClickStage('SUCCESS');
        } else {
          setActiveStep((perv => perv + 1));
          setExercises([...exercises, exercise]);
        }
    }

    const mathContextObject = {
        settings,
        setSettings,
        onClickStep,
        activeStep,
        setActiveStep,
        exercises,
        setExercises,
        ...values
    };

    return (
        <MathContext.Provider value={mathContextObject} >
            {children}
        </MathContext.Provider>
    )
}

export default MathContextProvider;


