import React from 'react';
import styles from './Welcome.module.css';
import { useMathContext } from '../MathExercise/MathContext';
import ReactGA from 'react-ga';

function Success() {
    
    const { setExercises, setActiveStep, onClickStage } = useMathContext();

    ReactGA.pageview('SUCCESS');

    const onClickStartOver = () => {
        onClickStage('EXERCISE');
        ReactGA.pageview('EXERCISE Startover');
        setExercises([]);
        setActiveStep(0);
    }

    const onClickWhatsappkButton = () => {
        ReactGA.pageview('whatsapp');
        window.open('whatsapp://send?text=רציתי לשתף אותך במשחק מעולה לתרגול מתמטיקה: https://math.sigalitam.com', '_blank', 'noopener,noreferrer');
    }

    const onClickHome = () => {
        onClickStage('WELCOME');
        setExercises([]);
        setActiveStep(0);
    }

    return (
        <>
            <div className={styles.welcomeWrapper}>
            <h1>כל הכבוד!</h1>
            <p>וואו! הצלחת לענות על כל השאלות נכון. <br />גם אם לפעמים זה היה לא הכי קל, <br /> כל הכבוד לך שלא ויתרת.</p>
            <p>בטח במשחק הבא תצליח לעשות זאת יותר בקלות ובמהירות  🤓</p>

         
            <button className="btn" onClick={onClickStartOver}>רוצה עוד משחק?</button> 
            </div>
            <div className={styles.welcomeFooter}>
                <div className={styles.home} onClick={onClickHome} />
                <div className={styles.whatsapp} onClick={onClickWhatsappkButton} />
            </div>
        </>
    );
}

export default Success;