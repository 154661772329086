import React, { useState } from 'react';
import './App.css';
import ExerciseMain from './component/MathExercise/ExerciseMain';
import Success from './component/Welcome/Success';
import Welcome from './component/Welcome/Welcome';
import MathContextProvider from './component/MathExercise/MathContext';
import ReactGA from 'react-ga';
import GameTimer from './component/UI/GameTimer';

function App() {
  const [stage, setStage] = useState('WELCOME');
  
  const onClickStage = (stage) => {
    setStage(stage);
  }

  const TRACKING_ID = "UA-56787825-5";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview('WELCOME');
  
  return (
    <div className="App">
      <MathContextProvider values={{onClickStage}}>
        <div className="wrapper">
          {stage === 'WELCOME' && <Welcome />}
          {stage === 'EXERCISE' && 
            <>
              <ExerciseMain />
              <GameTimer />
            </>
          }
          {stage === 'SUCCESS' && <Success />}
          
        </div>
       
      </MathContextProvider>
    </div>
  );
}

export default App;

