import React from 'react';
import styles from './ToggleButton.module.css';

function ToggleButton({setIsEditView, isEditView}) {

    const onToggleChange = (e) => {
        setIsEditView(prev => !prev);
    }

    return (
        <div className={styles.toggleButtonWrapper}>
            <label className={styles.switch}>
                <input type="checkbox" checked={isEditView} onChange={onToggleChange} />
                <span className={styles.slider}></span>
            </label>
        </div>
    );
}

export default ToggleButton;