import React, { useEffect, useRef, useState } from 'react';
import styles from './MathExercise.module.css';
import { useMathContext } from '../MathExercise/MathContext';

function MathExercise({ isEditView }) {

    const { settings, onClickStep } = useMathContext();

    const ANSWER_CORRECT = [
        'תשובה נכונה!',
        'זה היה קשה והצלחת',
        'נכון מאוד!', 
        'מעולה, בוא נמשיך',
        'אלוף האלופים',
        'נכון! תמיד האמנתי בך',
        'מהמם, פשוט גאון',
        'וואו, הצלחת בענק'
    ];

    const WRONG_ANSWER = [
        'לא נכון, נסה שוב',
        'אופס... תשובה לא נכונה', 
        'שאלה קשה, לא לוותר',
        'תחשוב טוב ותצליח',
        'זאת לא התשובה',
    ];    

    const maxLimitNumbers = settings.maxNumber;
    const [exercise, setExercise] = useState({numbers: [0, 0], outcome: 0, operator: settings.operators[0], done: false});
    const [showMessage, setShowMessage] = useState(false);
    const refAnswer = useRef();
    const settingsOperator = settings.operators.filter(item => item.checked);
    

    useEffect(() => {
        newExercise();
    }, []);

    const checkMyanswer = () => {
        if(+refAnswer.current.value === exercise.outcome){
            setExercise((prevState) => ({
                ...prevState,
                done: true,
            }));
            setShowMessage(false);
        } else {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);

        }
    }

    const outcomeExercise = (numbers, operator) => {
        let outcome;
        if(operator==='plus') outcome = numbers[0] + numbers[1];
        if(operator==='minus') outcome = numbers[0] - numbers[1];
        if(operator==='multiple') outcome = numbers[0] * numbers[1];
        return outcome;
    }

    const generateRandomNumber = () => {
        let rand = Math.random() * maxLimitNumbers;
        rand = Math.floor(rand);
        return rand;
    }

    const newExercise = () => {
        refAnswer.current.value = '';
        const newNumbers = exercise.numbers.map(num => generateRandomNumber());
        newNumbers.sort((a,b) => b - a);
        setShowMessage(false);

        const randomOperator = settingsOperator[Math.floor(Math.random()*settingsOperator.length)];
        setExercise((prevState) => ({
            ...prevState,
            done: false,
            numbers: newNumbers,
            outcome: outcomeExercise(newNumbers, randomOperator.value),
            operator: randomOperator,
        }));
    }

    const nextExercise = () => {
        onClickStep();
        newExercise();  
    }

    const getCustomMessage = (messageGroup) => messageGroup[Math.floor(Math.random()*messageGroup.length)];

    const onKeyOnlyNumbers = (event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
    }
    
    return (
        <div className={`${styles.wrapperExercise} ${isEditView && styles.isEditView}`}>
            <div className={styles.exerciseNumbers}>
                {exercise.numbers.map( num => 
                    <div key={Math.random()}>{num}</div>
                )}
                
            </div>
            <div className={styles.exerciseOperator}>
                {exercise.operator.symbol}
            </div>
            <div className={styles.break} />
            <div className={styles.devider} />
            <div className={styles.break} />
            <input pattern="[0-9]*" inputmod="numeric" onKeyPress={onKeyOnlyNumbers} className={styles.inputAnswer} disabled={exercise.done} ref={refAnswer} type="text" />
            <div className={styles.break} />
            {!exercise.done && 
                <>
                    {showMessage && <div className={styles.nextExerciseMessage}>{getCustomMessage(WRONG_ANSWER)}</div>}
                    <div className={styles.break} />
                    <button disabled={isEditView} className={`btn ${showMessage && 'btnRed'}`} onClick={checkMyanswer}>
                        {showMessage ? 'תשובה לא נכונה' : 'בדיקת התשובה'}
                    </button>
                </>
            }
            {exercise.done &&
                <>
                    <div className={styles.nextExerciseMessage}>{getCustomMessage(ANSWER_CORRECT)}</div>
                    <div className={styles.break} />
                    <button className="btn btnGreen" onClick={nextExercise}>
                        לשאלה הבאה
                    </button>
                </>  
            }
            <div className={styles.break} />
        </div>
    );
}

export default MathExercise;