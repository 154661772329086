import React from 'react';
import { useMathContext } from '../MathExercise/MathContext';
import styles from './Welcome.module.css';

function Settings({ onClickSettingsButton }) {

    const { setSettings, settings } = useMathContext();

    const settingsHandler = () => {
        onClickSettingsButton();
    }

    const handleChangeRadioMaxNumber = (e) => {
        setSettings((prevState) => ({
            ...prevState,
            maxNumber: e.target.value,
        }));
    }

    const handleChangeRadioSteps = (e) => {
        setSettings((prevState) => ({
            ...prevState,
            steps: e.target.value,
        }));
    }

    const handleOnOperatorChange = (position) => {
       const currentOperators = settings.operators;
       currentOperators[position].checked = !currentOperators[position].checked

       setSettings((prevState) => ({
            ...prevState,
            operators: currentOperators,
        }));
    }

    const isButtonDisabled = settings.operators.every(item => !item.checked);

    return (
        <div className={styles.wrapper}>
            <h1>הגדרות המשחק</h1>
            <p>כל מה שעליך לעשות זה לבחור את רמת השאלות, לענות ולהנות</p>

            <div className={styles.settingWrapper}>
                <b>אני רוצה תרגילים עם מספרים עד {settings.maxNumber}</b><br />
                <input 
                id="maxNumber" 
                type="range" 
                min="1" max="1000" 
                value={settings.maxNumber} 
                onChange={handleChangeRadioMaxNumber}
                className={styles.slider}
                step="1"/>
                <br /><br />
                <b>אני רוצה לענות על {settings.steps} תרגילים</b><br />
                <input 
                id="maxNumber" 
                type="range" 
                min="1" max="100" 
                value={settings.steps} 
                className={styles.slider}
                onChange={handleChangeRadioSteps}
                step="1"/>
                <br /><br />
                <b>פעולות החשבון שאני רוצה</b><br />
                <div className={styles.operatorWrapper}>
                    {settings.operators.map((item, index) => 
                    <label key={index} className={styles.operatorContainer}>
                        <input type="checkbox" 
                            name="operator" 
                            value={item.value} 
                            onChange={() => handleOnOperatorChange(index)}
                            checked={item.checked}
                        />
                        <span className={styles.operatorCheckmark}></span>
                        {item.name}
                    </label>
                    )}
                </div>
            </div>
            <button className="btn" disabled={isButtonDisabled} onClick={settingsHandler}>שמור לי את ההגדרות</button>
        </div>
    );
}

export default Settings;