import styles from './VerticalProgressbar.module.css';

function VerticalProgressbar({ steps=5, activeStep=0, exercises=[] }) {

    const renderSteps = () => {
        let stepsContent = [];
        for(let index = 0; index < steps; index++) {
            stepsContent.push(
                <li className={`${styles.stepProgressItem} ${activeStep === index && styles.current} ${activeStep > index && styles.isDone}`}>
                    <span>תרגיל {index+1}</span><br/>
                    <strong>{exercises[index]}</strong>
                </li>
            );
        }
        return stepsContent;
    };

    return (
    <div className={styles.progressbarWrapper}>
        <ul className={styles.stepProgress}>
            {renderSteps()}
        </ul>
     </div>
    );
}

export default VerticalProgressbar;