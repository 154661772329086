import React, { useState } from 'react';
import MathExercise from './MathExercise';
import VerticalProgressbar from '../UI/VerticalProgressbar';
import CanvasDraw from "react-canvas-draw";
import styles from './MathExercise.module.css';
import useWindowDimensions from '../UI/useWindowDimensions';
import ToggleButton from '../UI/ToggleButton';
import { useMathContext } from '../MathExercise/MathContext';

function ExerciseMain() {

    const [isEditView, setIsEditView] = useState(false);
    const { height, width } = useWindowDimensions();
    const { settings, activeStep, onClickStage, setExercises, setActiveStep } = useMathContext();

    const onClickBackButton = () => {
        onClickStage('WELCOME');
        setExercises([]);
        setActiveStep(0);
    }

    const progressbarWidth = `${(activeStep + 1) / settings.steps * 100}%`;

    return (
        <div>
            <div className={styles.exerciseTopMain}>
                <ToggleButton setIsEditView={setIsEditView} isEditView={isEditView} />
                <div className={styles.exerciseTitle}>
                    {`תרגיל ${activeStep + 1} מתוך ${settings.steps}`}
                     <div className={styles.progressbarBack}>
                        <div className={styles.progressbar} style={{width: progressbarWidth}}></div>
                    </div>
                </div>
                <div className={styles.arrowLeft} onClick={onClickBackButton} />
            </div>
            
            <div className={styles.exerciseMainWrapper}>
                {isEditView && 
                <CanvasDraw 
                    className={styles.canvasDraw}
                    brushRadius={2}
                    brushColor={'#f0884e'}
                    hideGrid={true}
                    enablePanAndZoom={false}
                    backgroundColor={'transparent'}
                    canvasHeight={height}
                    canvasWidth={width}
                    lazyRadius={0}
                />
                }
                <MathExercise isEditView={isEditView}/>
            </div>            
        </div>
    );
}

export default ExerciseMain;